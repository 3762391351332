import { UserConfig } from "../types";
import { setUserConfig } from "@lib/api-v2/mutation/useSetUserConfigMutation";
import { TutorialType } from "src/poc/types";

type UserConfigKeyMap = Record<`${TutorialType}`, boolean> & UserConfig;

type UserConfigKey = keyof UserConfigKeyMap;

export const updateUserConfig = async <K extends UserConfigKey>(
  key: K,
  value: UserConfigKeyMap[K],
) => {
  try {
    const res = await setUserConfig({
      body: { key, value },
    });
    return res;
  } catch (error) {
    console.error(`Failed to update user config for key: ${key}`, error);
    throw error;
  }
};
