/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  CustomUseMutationOptions,
  RequiredFetchRequest,
  RequiredFetchResponse,
  paths,
} from "../base.client";
import { client } from "../base.client";
import { useMutation } from "@tanstack/react-query";
import type { FetchOptions } from "openapi-fetch";

const ENDPOINT = "/v3/user-config";

type ENDPOINT = typeof ENDPOINT;
type Path = paths[ENDPOINT];
type Method = "put";
type Api = Path[Method];

type _FetchResponse = RequiredFetchResponse<ENDPOINT, Method>;
type _FetchRequest = RequiredFetchRequest<ENDPOINT, Method>;
export type SetUserConfigData = _FetchResponse["data"];
export type SetUserConfigError = _FetchResponse["error"];
export type SetUserConfigParams = _FetchRequest["params"];
export type SetUserConfigBody = _FetchRequest["body"];

export const setUserConfig = async (options: FetchOptions<Api>) => {
  return await client.PUT(ENDPOINT, options);
};

const errorTypeGuard = (x: unknown, y: unknown): x is SetUserConfigData => {
  return !y;
};

export function useSetUserConfigMutation(
  options?: CustomUseMutationOptions<ENDPOINT, Method>,
  fetchOptions?: Partial<FetchOptions<Api>>,
) {
  return useMutation({
    ...options,
    mutationFn: async ({ params, body }) => {
      const { data, error, response } = await setUserConfig({
        params,
        body,
        ...fetchOptions,
      });

      if (errorTypeGuard(data, error)) {
        return data;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw { ...error, meta: options?.meta, response };
    },
  });
}
