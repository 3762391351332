/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  CustomUseQueryOptions,
  RequiredFetchRequest,
  RequiredFetchResponse,
  paths,
} from "../base.client";
import { client } from "../base.client";
import { useQuery } from "@tanstack/react-query";
import type { FetchOptions } from "openapi-fetch";

const ENDPOINT = "/v3/user-config";

type ENDPOINT = typeof ENDPOINT;
type Path = paths[ENDPOINT];
type Method = "get";
type Api = Path[Method];

type _FetchResponse = RequiredFetchResponse<ENDPOINT, Method>;
type _FetchRequest = RequiredFetchRequest<ENDPOINT, Method>;
export type GetUserConfigData = _FetchResponse["data"];
export type GetUserConfigError = _FetchResponse["error"];
export type GetUserConfigParams = _FetchRequest["params"];
export type GetUserConfigBody = _FetchRequest["body"];

export const getUserConfig = async (options: FetchOptions<Api>) => {
  return await client.GET(ENDPOINT, options);
};

export const getUserConfigQueryKey = (
  params?: GetUserConfigParams,
): readonly unknown[] => (params ? [ENDPOINT, params] : [ENDPOINT]);

export function useGetUserConfigQuery(
  { params, body, ...options }: CustomUseQueryOptions<ENDPOINT, Method>,
  fetchOptions?: Partial<FetchOptions<Api>>,
) {
  return useQuery({
    ...options,
    queryKey: getUserConfigQueryKey(params),
    queryFn: async ({ signal }) => {
      const { data, error, response } = await getUserConfig({
        params,
        signal,
        body,
        ...fetchOptions,
      });

      if (data) {
        return data;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw { ...error, meta: options?.meta, response };
    },
  });
}
