type PathParams = Record<string, string | undefined | number>;

function objectToQueryString(obj: PathParams): string {
  if (Object.keys(obj).length === 0 || !Object.keys) {
    return "";
  }

  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined && value !== null) {
      params.append(key, String(value));
    }
  }

  // 쿼리 스트링 시작 부분에 '?'를 추가
  const queryString = params.toString();
  return queryString ? "?" + queryString : "";
}

export type { PathParams };
export { objectToQueryString };
