import { useEffect, useRef } from "react";

export function useSetScrollSnapCurrentIdx({
  setCurrentIdx,
  totalCount,
}: {
  setCurrentIdx: (idx: number) => void;
  totalCount: number;
}) {
  const scrollWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleMove = () => {
    if (!scrollWrapperRef.current) return;

    const offsetWidth = scrollWrapperRef.current.scrollWidth / totalCount;
    const left = scrollWrapperRef.current?.scrollLeft || 0;
    const idx = left / offsetWidth;
    setCurrentIdx(Math.round(idx));
  };

  useEffect(() => {
    handleMove();
    scrollWrapperRef.current?.addEventListener("scroll", handleMove);
    return () =>
      scrollWrapperRef.current?.removeEventListener("scroll", handleMove);
  }, []);
  return { scrollWrapperRef };
}
