"use client";

import RoundButton from "@hits/hits-ui/button/round.button";
import {
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from "@hits/hits-ui/dropdown/round.dropdown";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

export default function SupportButton({ locale }: { locale: string }) {
  const t = useTranslations();
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className="flex h-16 items-center"
      onMouseLeave={() => setIsHover(false)}
      onMouseOver={() => setIsHover(true)}
    >
      <DropdownTrigger asChild>
        <RoundButton
          className="aria-expanded:bg-home-gnb-hover px-3 hover:bg-blue-400/20 hover:text-blue-200 focus:outline-none aria-expanded:text-blue-200"
          color="ghost"
          data-cy="SupportButton-trigger"
          size="m"
        >
          {t("HOME.GNB.support")}
          <MdOutlineKeyboardArrowDown className="h-4 w-4" />
        </RoundButton>
      </DropdownTrigger>

      <DropdownContent
        className="w-[117px]"
        data-cy="SupportButton-content"
        forceMount={isHover || undefined}
        sideOffset={4}
      >
        <DropdownItem asChild className="text-gray-c-700">
          <Link
            data-cy="SupportButton-docs-link"
            href="https://docs.hyperlab.hits.ai/docs/"
            target="_blank"
          >
            {t("HOME.GNB.docs")}
          </Link>
        </DropdownItem>
        <DropdownItem asChild className="text-gray-c-700">
          <Link
            data-cy="SupportButton-release-note-link"
            href={`https://docs.hyperlab.hits.ai/hyper-lab-release-note${locale === "en" ? "-en" : ""}/`}
            target="_blank"
          >
            {t("HOME.GNB.releaseNote")}
          </Link>
        </DropdownItem>
      </DropdownContent>
    </div>
  );
}
