"use client";

import SignOutButton from "../SignOutButton";
import HamburgerMenuAccordion from "./HamburgerMenuAccordion";
import {
  DropdownContent,
  DropdownRoot,
  DropdownTrigger,
} from "@hits/hits-ui/dropdown/round.dropdown";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { ReactNode, useEffect, useRef, useState } from "react";
import { MdClose, MdOutlineMenu } from "react-icons/md";

function RowLinkItem({
  className,
  href,
  children,
}: {
  className?: string;
  href: string;
  children: ReactNode;
}) {
  return (
    <Link
      className={clsx(
        "tablet_768:px-10 flex h-12 items-center rounded-none px-5 hover:bg-blue-400/20 hover:text-blue-300",
        className,
      )}
      href={href}
    >
      {children}
    </Link>
  );
}

// TODO : Refactor this component  : login과 그렇지 않은 상태의 컴포넌트를 아예 분리해버리기
// TODO :  URL바뀌면 modal Close

export default function HamburgerMenu({
  locale,
  login,
}: {
  locale: string;
  login: boolean;
}) {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      //! tailwind config gnbHamburgerHide에 의존성있음
      if (window.innerWidth >= 1080) {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <DropdownRoot modal={false} open={isOpen} onOpenChange={setIsOpen}>
      <DropdownTrigger
        aria-label="hamburger-menu"
        className="group cursor-pointer rounded-md transition-colors hover:bg-blue-400/20 data-[state=open]:bg-blue-400/20"
      >
        <div className="cursor-pointer">
          <MdClose className="h-6 w-6 fill-white group-hover:fill-blue-300 group-data-[state=closed]:hidden group-data-[state=open]:fill-blue-300" />
          <MdOutlineMenu className="h-6 w-6 fill-white group-hover:fill-blue-300 group-data-[state=open]:hidden" />
        </div>
      </DropdownTrigger>
      <div className="hidden w-screen" ref={ref} />

      <DropdownContent
        className="w-screen gap-0 rounded-none border-t-2 border-t-[#1D222C] bg-[#11141E] p-0 text-white shadow-none"
        // ref={ref}
        sideOffset={16}
      >
        <>
          {login && (
            <RowLinkItem href={`/${locale}/notice`}>
              {t("HOME.GNB.notice")}
            </RowLinkItem>
          )}
          <RowLinkItem href={`/${locale}/features`}>
            {t("HOME.GNB.features")}
          </RowLinkItem>
          <RowLinkItem href={`/${locale}/pricing/for-profit`}>
            {t("HOME.GNB.pricePolicy")}
          </RowLinkItem>
          <RowLinkItem href={`/${locale}/faq`}>{t("HOME.GNB.faq")}</RowLinkItem>
        </>

        <RowLinkItem href={`/${locale}/blog`}>{t("HOME.GNB.blog")}</RowLinkItem>

        <HamburgerMenuAccordion locale={locale} />

        <RowLinkItem href={`/${locale}/service-introduction-apply`}>
          {t("HOME.GNB.serviceIntroduction")}
        </RowLinkItem>

        {login ? (
          <RowLinkItem href={`/${locale}/lab-list`}>
            <div className="flex h-full w-full items-center border-b-2 border-b-white/10">
              {t("HOME.GNB.startHyperlab")}
            </div>
          </RowLinkItem>
        ) : (
          <RowLinkItem href={`/${locale}/auth/signup`}>
            <div className="flex h-full w-full items-center border-b-2 border-b-white/10">
              {t("HOME.GNB.freeTrial")}
            </div>
          </RowLinkItem>
        )}

        {login ? (
          <SignOutButton
            className="w-ful tablet_768:px-10 h-12 px-5 text-left text-[14px] hover:bg-blue-400/20 hover:text-blue-300"
            signoutMsgContents={t.rich("HOME.ProfileDropdown.signOutContents", {
              br: () => "\n",
            })}
            signoutMsgTitle={t("HOME.ProfileDropdown.signOutTitle")}
          >
            {t("HOME.GNB.signOut")}
          </SignOutButton>
        ) : (
          <RowLinkItem href={`/${locale}/auth/signin`}>
            {t("HOME.GNB.signIn")}
          </RowLinkItem>
        )}
      </DropdownContent>
    </DropdownRoot>
  );
}
