import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

export const WindowSizeType = {
  pc_1440: 1440,
  pc_1024: 1024,
  tablet_768: 768,
  tablet_480: 480,
  mobile_390: 390,
  mobile: 0,
} as const;
export function useGetWindowSize() {
  const [windowSize, setWindowSize] = useState(1024);

  useEffect(() => {
    const debouncedResize = debounce(() => {
      setWindowSize(window.innerWidth);
    }, 300);

    setWindowSize(window.innerWidth);
    window.addEventListener("resize", debouncedResize);
    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  return { windowSize };
}
