import { V2UsersPatchData } from "@lib/api-v2/mutation/useV2UsersPatchMutation";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type GlobalUserState = V2UsersPatchData & {
  setUser: (user: Omit<GlobalUserState, "setUser" | "reset">) => void;
  reset: () => void;
};

const initUserState: V2UsersPatchData = {
  user_id: "",
  email: "",
  name: "",
  email_verified: false,
  created_at: "",
  updated_at: "",
};

export const useGlobalUser = create<GlobalUserState>()(
  persist(
    (set) => ({
      ...initUserState,
      setUser: (user) => set(user),
      reset: () =>
        set({
          ...initUserState,
        }),
    }),
    {
      name: "GlobalUser",
    },
  ),
);
