"use client";

import { useGlobalUser } from "@hooks/global/useGlobalUser";
import { useV2AuthSignoutPostMutation } from "@lib/api-v2/mutation/useV2AuthSignoutPostMutation";
import { auth, signOut } from "@shared/lib/auth/firebase";

type onSuccessCallbackParams = {
  data: unknown;
  variables: unknown;
  context: unknown;
};

type onErrorCallbackParams = {
  error: unknown;
  variables: unknown;
  context: unknown;
};

interface UserSignOutProps {
  onSuccessCallback?: ({
    data,
    variables,
    context,
  }: onSuccessCallbackParams) => void;
  onErrorCallback?: ({
    error,
    variables,
    context,
  }: onErrorCallbackParams) => void;
}

const useSignOut = ({ onSuccessCallback }: UserSignOutProps = {}) => {
  const userStateReset = useGlobalUser((state) => state.reset);
  return useV2AuthSignoutPostMutation({
    onMutate: () => {
      void signOut(auth).catch();
    },
    onSuccess: (data, variables, context) => {
      userStateReset();
      onSuccessCallback?.({ data, variables, context });
    },
  });
};

export default useSignOut;
