"use client";

import SignOutButton from "./SignOutButton";
import { useGetUserConfigDataWrapper } from "@entities/user-config/api";
import RoundButton, { RoundButtonCss } from "@hits/hits-ui/button/round.button";
import { objectToQueryString } from "@shared/utils/url/object-to-query-string";
import { useTranslations } from "next-intl";
import Link from "next/link";

const hiddenUnder800 = "gnbHamburgerHide:visible gnbHamburgerShow:hidden";

// TODO : Refactor this component  : login과 그렇지 않은 상태 분리하기

export default function ConditionalButtonsByLogin({
  locale,
  session,
}: {
  locale: string;
  session: Record<string, never> | null;
}) {
  const t = useTranslations("HOME");
  const { data: userConfigData } = useGetUserConfigDataWrapper({
    enabled: !!session,
  });
  const startHref = (() => {
    if (userConfigData?.recentPagePath || userConfigData?.lastLabSpaceId) {
      const params = objectToQueryString({
        redirectUrl: userConfigData.recentPagePath,
        labSpaceId: userConfigData.lastLabSpaceId,
      });
      return `/${locale}/lab/auth/2fa${params}`;
    }
    return `/${locale}/lab-list`;
  })();

  if (session) {
    return (
      <>
        <SignOutButton
          className={RoundButtonCss({
            color: "ghost",
            size: "m",
            className: `hover:bg-home-sign-in-hover active:bg-gray-w-900 px-4 backdrop-blur-sm ${hiddenUnder800} bg-gray-w-900 text-white`,
          })}
          signoutMsgContents={t.rich("ProfileDropdown.signOutContents", {
            br: () => "\n",
          })}
          signoutMsgTitle={t("ProfileDropdown.signOutTitle")}
        >
          {t("GNB.signOut")}
        </SignOutButton>
        <Link className={hiddenUnder800} href={startHref}>
          <RoundButton
            className="hover:bg-home-free-trial-hover button2 bg-[#26F1EA] text-[#020E20] backdrop-blur-sm"
            color="ghost"
            size="m"
          >
            {t("GNB.startHyperlab")}
          </RoundButton>
        </Link>
      </>
    );
  }

  return (
    <>
      <Link
        className={`${hiddenUnder800} font-medium`}
        href={`/${locale}/auth/signin`}
      >
        <RoundButton
          className="hover:bg-home-sign-in-hover active:bg-gray-w-900 bg-gray-w-900 px-4 text-white backdrop-blur-sm"
          color="ghost"
          data-cy="ConditionalButtonsByLogin-signin"
          size="m"
        >
          {t("GNB.signIn")}
        </RoundButton>
      </Link>
      <Link className={hiddenUnder800} href={`/${locale}/auth/signup`}>
        <RoundButton
          className="hover:bg-home-free-trial-hover bg-[#26F1EA] px-4 text-[#020E20] backdrop-blur-sm"
          color="ghost"
          size="m"
        >
          {t("GNB.freeTrial")}
        </RoundButton>
      </Link>
    </>
  );
}
