import { UserConfig } from "../types";
import { CustomUseQueryOptions, paths } from "@lib/api-v2/base.client";
import { useGetUserConfigQuery } from "@lib/api-v2/query/useGetUserConfigQuery";
import { FetchOptions } from "openapi-fetch";

const ENDPOINT = "/v3/user-config";

/**캐시 무효화 및 타입 정의를 위해 wrapper 함수를 사용합니다. */
export function useGetUserConfigDataWrapper(
  { params, body, ...options }: CustomUseQueryOptions<typeof ENDPOINT, "get">,
  fetchOptions?: Partial<FetchOptions<paths[typeof ENDPOINT]["get"]>>,
) {
  const query = useGetUserConfigQuery(
    { params, body, cacheTime: 0, ...options },
    fetchOptions,
  );
  const data = query.data as UserConfig;

  return { query, data };
}
