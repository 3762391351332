import { cva } from "class-variance-authority";
import { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

const COLOR_TABLE = {
  blue: {
    color: "#228BE6",
    bgColor: "#228BE6",
    startRGB: "#E7F5FF",
    midRGB: "#74C0FC",
    endRGB: "#339AF0",
  },
  skyblue: {
    color: "#228BE6",
    bgColor: "#D0EBFF",
    startRGB: "#E7F5FF",
    midRGB: "#74C0FC",
    endRGB: "#339AF0",
  },
  "outline-skyblue": {
    color: "#228BE6",
    bgColor: "#fff",
    startRGB: "#E7F5FF",
    midRGB: "#74C0FC",
    endRGB: "#339AF0",
  },
  gray: {
    color: "#7B8D9F",
    bgColor: "#F4F6F9",
    startRGB: "#DAE2E7",
    midRGB: "#B8C5CF",
    endRGB: "#98A8B7",
  },
  "outline-gray": {
    color: "#7B8D9F",
    bgColor: "#fff",
    startRGB: "#DAE2E7",
    midRGB: "#B8C5CF",
    endRGB: "#98A8B7",
  },
  green: {
    color: "#12B886",
    bgColor: "#C3FAE8",
    startRGB: "#E6FCF5",
    midRGB: "#63E6BE",
    endRGB: "#20C997",
  },
  red: {
    color: "#E03131",
    bgColor: "#FA5252",
    startRGB: "#FFF5F5",
    midRGB: "#FFA8A8",
    endRGB: "#FF6B6B",
  },
  ghost: {
    color: "#fff",
    bgColor: "#fff",
    startRGB: "#E7F5FF",
    midRGB: "#74C0FC",
    endRGB: "#339AF0",
  },
} as const;
export interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  color?: keyof typeof COLOR_TABLE;
  bgColor?: string;
  size?: "xs" | "s" | "m" | "l" | "ghost" | null;
}

const SpinnerVariants = cva(["w-5.5 h-5.5 animate-spin"], {
  variants: {
    size: {
      ghost: "",
      l: "w-5.5 h-5.5",
      m: "w-5 h-5",
      s: "w-5 h-5",
      xs: "w-4.5 h-4.5",
    },
  },
});

export const SpinnerCss = (props: Parameters<typeof SpinnerVariants>[number]) =>
  twMerge(SpinnerVariants(props));

function roundColor(color?: keyof typeof COLOR_TABLE) {
  return `conic-gradient(from 0deg at 50% 50%, 
  ${color ? COLOR_TABLE[color].startRGB : "#E7F5FF"} 0deg,
  ${color ? COLOR_TABLE[color].midRGB : "#74C0FC"} 180deg,
  ${color ? COLOR_TABLE[color].endRGB : "#339AF0"} 360deg)`;
}
export default function Spinner({
  color,
  /**
   * 배경색을 설정합니다. 기본적으로 버튼의 색상을 사용합니다.
   */
  bgColor,
  size,
  ...props
}: SpinnerProps) {
  return (
    <div
      className={SpinnerCss({ size })}
      style={{
        borderRadius: "100%",
        border: "4px solid transparent",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        backgroundImage: `linear-gradient(${bgColor || (color ? COLOR_TABLE[color].bgColor : "#fff")}, ${bgColor || (color ? COLOR_TABLE[color].bgColor : "#fff")}), ${roundColor(color)}`,
      }}
      {...props}
    ></div>
  );
}
