"use client";

import { cn } from "../utils/classname";
import * as _Accordion from "@radix-ui/react-accordion";
import { forwardRef } from "react";

const Root = _Accordion.Root;
const Trigger = forwardRef<HTMLButtonElement, _Accordion.AccordionTriggerProps>(
  ({ children, ...params }, ref) => {
    return (
      <_Accordion.Header>
        <_Accordion.Trigger {...params} ref={ref}>
          {children}
        </_Accordion.Trigger>
      </_Accordion.Header>
    );
  },
);
Trigger.displayName = "Trigger";

const Content = forwardRef<HTMLDivElement, _Accordion.AccordionContentProps>(
  ({ className, ...props }, ref) => {
    return (
      <_Accordion.Content
        className={cn(
          "data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp",
          className,
        )}
        ref={ref}
        {...props}
      >
        {props.children}
      </_Accordion.Content>
    );
  },
);
Content.displayName = "Content";

const Item = forwardRef<HTMLDivElement, _Accordion.AccordionItemProps>(
  ({ className, ...props }, ref) => {
    return (
      <_Accordion.Item {...props} className={cn(className)} ref={ref}>
        {props.children}
      </_Accordion.Item>
    );
  },
);
Item.displayName = "Item";

const Accordion = {
  Root,
  Trigger,
  Content,
  Item,
};

export default Accordion;
