"use client";

import { useGlobalConfirmModal } from "@app/[lang]/components/Modal/GlobalConfirmModal";
import { useGlobalUser } from "@hooks/global/useGlobalUser";
import useSignOut from "@hooks/global/useSignOut";
import { useHardRouter } from "@shared/hooks";
import { usePathname } from "next/navigation";
import { ComponentProps, ReactNode, forwardRef } from "react";

// TODO 훅으로 만들기
const SignOutButton = forwardRef<
  HTMLButtonElement,
  Omit<ComponentProps<"button">, "onclick"> & {
    signoutMsgTitle: ReactNode;
    signoutMsgContents: ReactNode;
  }
>(({ signoutMsgTitle, signoutMsgContents, ...props }, ref) => {
  const { resetState, setModalState } = useGlobalConfirmModal();
  const router = useHardRouter();
  const pathname = usePathname();
  const signoutMutation = useSignOut({
    onSuccessCallback: () => {
      router.replace(pathname.split("/").slice(0, 3).join("/"));
      useGlobalUser.getState().reset();
    },
  });

  return (
    <button
      onClick={() => {
        setModalState({
          open: true,
          title: signoutMsgTitle,
          contents: signoutMsgContents,
          onConfirm: () => {
            signoutMutation.mutate({});
            resetState();
          },
        });
      }}
      {...props}
      ref={ref}
    />
  );
});
SignOutButton.displayName = "SignOutButton";

export default SignOutButton;
