import { useRouter } from "next/navigation";
import { useCallback, useMemo, useTransition } from "react";

export function useHardRouter() {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();

  const replace = useCallback(
    (href: string) => {
      startTransition(() => {
        router.replace(href);
        router.refresh();
      });
    },
    [router],
  );

  const push = useCallback(
    (href: string) => {
      startTransition(() => {
        router.push(href);
        router.refresh();
      });
    },
    [router],
  );

  return useMemo(
    () => ({
      replace,
      push,
      refresh: router.refresh.bind(router),
      isPending,
    }),
    [isPending, push, replace, router],
  );
}
